// const host = 'auth.staging.gorillascode.com';
const host = window.location.hostname;
const configs: any = {
    'localhost': {
        apiUrl: 'http://localhost:5001',
        name: 'development',
    },
    'auth.staging.gorillascode.com': {
        apiUrl: 'https://api.auth.staging.gorillascode.com/',
        name: 'staging',
    },
    'auth.quiteja.com.br': {
        apiUrl: 'https://api.auth.gorillascode.com',
        name: 'production',
    },
};

if (!(Object.keys(configs).includes(host))) {
    configs[host] = {
        apiUrl: `http://${host}:5001`,
    }
}


export default {
    api: configs[host].apiUrl,
    name: configs[host].name,
}

