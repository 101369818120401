
import { mapActions } from "vuex";
import { getLogActions } from "@/services/logs";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getApps } from "@/services/apps";
import * as Bowser from "bowser";

@Component({
  filters: {
    formatDate(date: Date) {
      if (date) {
        let newDate = new Date(date);
        let day: number = newDate.getDate();
        let month: number = newDate.getMonth() + 1;
        let year: number = newDate.getFullYear();
        let hours: number = newDate.getHours();
        let minutes: number = newDate.getMinutes();

        if (day < 10) day = 0 + day;
        if (month < 10) month = 0 + month;
        if (minutes < 10) minutes = 0 + minutes;
        if (hours < 10) hours = 0 + hours;

        return day + "/" + month + "/" + year + " " + hours + ":" + minutes;
      }
      return "-";
    },

    formatUserAgent(agent: string): string {
      if (!agent) {
        return "";
      }

      const userAgent = Bowser.parse(agent);
      const browserVersion = (userAgent.browser.version || "").split(".")[0];
      const osVersion = userAgent.os.version || "";
      const parseUserAgent = `${userAgent.browser.name} ${browserVersion} - ${userAgent.os.name} ${osVersion}`;
      return parseUserAgent;
    },
  },
  data() {
    return {
      appsLoading: false,
    };
  },
  methods: {
    ...mapActions("logsState", {
      getLogs: "actionsLogs",
    }),
    ...mapActions("usersState", {
      getUsers: "actionsUsers",
    }),
  },
})
export default class Logs extends Vue {
  /* eslint-disable */
  getLogs: any;
  getUsers: any;

  appsLoading = false;
  apps: any = [];
  activeApp: any = null;
  async getAllApps() {
    if (this.apps.length === 0) {
      this.appsLoading = true;
      const { data }: any = await getApps();
      this.apps = data.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.appsLoading = false;
    }
  }

  oldPage: any;
  minDate: any = "";
  users: any = [];
  actions: any = [];

  lock = true;
  menuEndTime = false;
  menuEndDate = false;
  menuStartTime = false;
  menuStartDate = false;
  menuStartDateTime = false;
  menuEndDateTime = false;

  // Tabela
  loadingData = false;
  loadingTable = true;
  actionsLoading = false;
  usersLoading = false;
  logs: any = [];
  options: any = {
    page: 1,
  };
  headers = [
    {
      text: "Executado em",
      value: "executed_at",
      align: "start",
      sortable: false,
      width: "10%",
    },
    {
      text: "Usuário",
      value: "executer_id",
      align: "start",
      sortable: false,
      width: "10%",
    },
    {
      text: "Aplicação",
      value: "app_name",
      align: "start",
      sortable: false,
      width: "10%",
    },
    {
      text: "Ação",
      value: "action",
      align: "start",
      sortable: false,
      width: "10%",
    },
    {
      text: "Descrição",
      value: "description",
      align: "start",
      sortable: false,
    },
    {
      text: "IP",
      value: "ip_address",
      align: "start",
      sortable: false,
      width: "10%",
    },
    {
      text: "Navegador",
      value: "executer_user_agent",
      align: "start",
      sortable: false,
    },
  ];
  pagination: any = {
    total: 0,
    itemsPerPage: 20,
    footer: {
      disablePagination: false,
      disableItemsPerPage: false,
      "items-per-page-options": [20, 50, 100],
    },
  };

  // Formulário
  startDate: any = "";
  startDateFormatted: any = "";
  startTime: any = "";
  startDateTimeFormatted: any = "";
  endDate: any = "";
  endDateFormatted: any = "";
  endTime: any = "";
  endDateTimeFormatted: any = "";
  activeUser: any = null;
  executerUserAgent: any = "";
  passiveUser: any = null;
  action: any = null;
  description: any = "";
  debounce: any = null;
  previousDescription: any = "";
  ip: any = "";
  refresh = false;

  get fetchUsers(): null | string[] {
    return this.users != []
      ? this.users.sort((a: any, b: any) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
      : null;
  }

  @Watch("options")
  optionsChanged(): void {
    if (this.loadingData || this.lock) return;
    this.changeFilters();
  }

  @Watch("startDate")
  startDateChanged(): void {
    if (this.lock) return;
    if (this.startDate !== "") {
      this.startDateFormatted = this.formatDate(this.startDate);

      if (!this.startTime) {
        this.startTime = "00:00";
      }
      this.concatDateTime("start");
    }
  }

  @Watch("endDate")
  endDateChanged(): void {
    if (this.lock) return;
    if (this.startDate !== "") {
      this.endDateFormatted = this.formatDate(this.endDate);

      if (!this.endTime) {
        this.endTime = "23:59";
      }
      this.concatDateTime("end");
    }
  }

  @Watch("loadingData")
  loadingDataChanged(): void {
    if (this.lock) return;
    this.pagination.footer.disablePagination = this.loadingData;
    this.pagination.footer.disableItemsPerPage = this.loadingData;
  }

  debounceSearch(e: any): void {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.description = e;
      this.changeFilters();
    }, 1000);
  }

  getActions(): void {
    this.actionsLoading = true;
    if (this.actions.length === 0) {
      getLogActions().then((resp: any) => {
        resp.data.forEach((action: any) => {
          let text = this.transformAction(action);
          this.actions.push({
            value: action,
            text: text,
          });
        });
      });
    }
    this.actionsLoading = false;
  }

  transformAction(action: any): any {
    let transformedAction: any;
    if (action == "CREATE") transformedAction = "Criou";
    else if (action == "UPDATE") transformedAction = "Atualizou";
    else if (action == "DELETE") transformedAction = "Deletou";
    else if (action == "REGISTER") transformedAction = "Cadastrou";
    else if (action == "UNREGISTER") transformedAction = "Descadastrou";
    else if (action == "ACTIVATE") transformedAction = "Ativou";
    else if (action == "DEACTIVATE") transformedAction = "Desativou";
    else if (action == "REQUESTED") transformedAction = "Solicitou";
    else if (action == "SIGNIN") transformedAction = "Login";
    else if (action == "SIGNIN_2FA") transformedAction = "Login 2FA";
    else if (action == "SECURE_SIGNIN") transformedAction = "Login seguro";
    else if (action == "SIGNIN_ERROR") transformedAction = "Erro de login";
    else if (action == "SIGNOUT") transformedAction = "Logout";
    else if (action == "ACCESSED") transformedAction = "Acessou";
    else if (action == "PROFILE_UPDATE") transformedAction = "Atualizou perfil";
    else if (action == "USER_UPDATE") transformedAction = "Atualizou usuário";
    else if (action == "ROLE_UPDATE") transformedAction = "Atualizou permissão";
    else {
      let captalizedAction = action
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ");
      captalizedAction.forEach((action: any, index: number): void => {
        if (index > 0) return;
        let captalize = action.split("");
        captalize[0] = captalize[0]?.toUpperCase();
        captalizedAction[index] = captalize.join("");
      });
      return captalizedAction.join(" ");
    }

    return transformedAction;
  }
  async getAllUsers(): Promise<void> {
    this.usersLoading = true;
    if (this.users.length == 0) {
      await this.getUsers(["name", "email", "profile_photo", "active", "id"])
        .then((users: any) => {
          this.users = users;
        })
        .catch((error: any) => {
          console.error(error);
          this.$emit("snackbar", {
            message: "Erro ao carregar os usuários!",
          });
        });
    }
    this.usersLoading = false;
  }

  async created(): Promise<void> {
    this.minDate = this.parseDate(this.formatDate(new Date().toISOString()));
    if (this.$route.query.hasOwnProperty("executer_id")) {
      await this.getAllUsers();
    }
    if (this.$route.query.hasOwnProperty("app_id")) {
      await this.getAllApps();
    }
    Object.entries(this.$route.query).forEach(([key, value]) => {
      let data: any;
      switch (key) {
        case "date_start":
          data = (value + "").split(" ");
          this.startDate = data[0];
          this.startTime = data[1].split(":").splice(0, 2).join(":");
          break;
        case "date_end":
          data = (value + "").split(" ");
          this.endDate = data[0];

          this.endTime = data[1].split(":").splice(0, 2).join(":");
          break;
        case "app_id":
          this.activeApp = {};
          this.activeApp = value
            ? this.apps.find((item: { id: string }) => item.id === value)
            : "";
          break;
        case "executer_id":
          this.activeUser = {};
          this.activeUser = value
            ? this.users.find((item: { id: string }) => item.id === value)
            : "";
          break;
        case "on_user_id":
          this.passiveUser = {};
          this.passiveUser = value
            ? this.users.find((item: { id: string }) => item.id === value)
            : "";
          break;
        case "action":
          this.action = {};
          if (value) {
            this.action.text = value !== "" ? this.transformAction(value) : "";
            this.action.value = value ? value : "";
          }
          break;
        case "description":
          this.description = value;
          break;
        case "ip_address":
          this.ip = value;
          break;
        case "executer_user_agent":
          this.executerUserAgent = value;
          break;
        case "page":
          this.options.page = parseInt(value + "");
          break;
        case "rows":
          this.pagination.itemsPerPage = parseInt(value + "");
          break;
      }
    });

    this.lock = false;
    this.changeFilters();
  }

  setStartTime(e: any): void {
    if (e !== undefined) {
      let startPicker: any = this.$refs.startTimePicker;
      if (startPicker.selecting === 2) {
        this.startTime = e;
        startPicker.selecting = 0;
        startPicker.selecting = 1;

        this.concatDateTime("start");
      }
    }
  }

  setEndTime(e: any): void {
    if (e !== undefined) {
      let endPicker: any = this.$refs.endTimePicker;
      if (endPicker.selecting === 2) {
        this.endTime = e;
        endPicker.selecting = 0;
        endPicker.selecting = 1;

        this.concatDateTime("end");
      }
    }
  }

  clearInitialDateData(): void {
    this.menuStartTime = false;
    this.menuStartDate = false;
    this.menuStartDateTime = false;

    this.startTime = "";
    this.startDate = "";
    this.startDateFormatted = "";
    this.startDateTimeFormatted = "";

    this.concatDateTime("clear");
  }

  clearFinalDateData(): void {
    this.menuEndTime = false;
    this.menuEndDate = false;
    this.menuEndDateTime = false;

    this.endTime = "";
    this.endDate = "";
    this.endDateFormatted = "";
    this.endDateTimeFormatted = "";

    this.concatDateTime("clear");
  }

  concatDateTime(noun: string): void {
    if (noun === "start") {
      this.startDateTimeFormatted = `${this.startDateFormatted} ${this.startTime}`;
    }
    if (noun === "end") {
      this.endDateTimeFormatted = `${this.endDateFormatted} ${this.endTime}`;
    }
    this.changeFilters();
  }

  changeFilters(): void {
    let payload: any = {
      executer_user_agent: null,
      date_start: null,
      date_end: null,
      app_id: null,
      executer_id: null,
      on_user_id: null,
      action: null,
      description: null,
      ip_address: null,
      rows: null,
      page: null,
    };

    let date = new Date();
    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    let hoje = year + "-" + month + "-" + day;

    // Data de início
    if (this.startDate) {
      payload.date_start = this.startDate;
    } else {
      payload.date_start = hoje;
    }

    if (this.startTime) {
      payload.date_start += " " + this.startTime + ":00";
      this.startDateTimeFormatted = `${this.formatDate(hoje)} ${
        this.startTime
      }`;
    } else if (this.startDate) {
      payload.date_start += " 00:00:00";
    } else {
      payload.date_start = null;
    }

    // Data de fim
    if (this.endDate) {
      payload.date_end = this.endDate;
    } else {
      payload.date_end = hoje;
    }

    if (this.endTime) {
      payload.date_end += " " + this.endTime + ":59";
      this.endDateTimeFormatted = `${this.formatDate(hoje)} ${this.endTime}`;
    } else if (this.endDate) {
      payload.date_end += " 23:59:59";
    } else {
      payload.date_end = null;
    }

    if (this.activeApp) {
      if (typeof this.activeApp === "object" && this.activeApp !== null) {
        payload.app_id = this.activeApp.id;
      } else {
        payload.app_id = this.activeApp;
      }
    }

    // Agente do executor
    payload.executer_user_agent = this.executerUserAgent
      ? this.executerUserAgent
      : null;

    // Usuário ativo
    if (typeof this.activeUser === "object" && this.activeUser !== null) {
      payload.executer_id = this.activeUser ? this.activeUser.id : null;
    } else {
      payload.executer_id = this.activeUser ? this.activeUser : null;
    }

    // Usuário passivo
    if (typeof this.activeUser === "object" && this.activeUser !== null) {
      payload.on_user_id = this.activeUser ? this.activeUser.id : null;
    } else {
      payload.on_user_id = this.activeUser ? this.activeUser : null;
    }

    // Ação
    if (typeof this.action === "object" && this.action !== null) {
      payload.action = this.action ? this.action.value : null;
    } else {
      payload.action = this.action ? this.action : null;
    }

    // Descrição
    payload.description = this.description ? this.description : null;

    // Ip
    payload.ip_address = this.ip ? this.ip : null;

    // Paginação
    payload.rows = this.options.itemsPerPage;

    if (this.options.page == this.oldPage) {
      payload.page = 1;
      this.oldPage = 1;
    } else {
      payload.page = this.options.page;
      this.oldPage = this.options.page;
    }

    this.getDataLogs(payload);
  }

  formatDate(date: any): string {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  parseDate(date: any): string {
    if (!date) return "";

    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  getDataLogs(payload: any = {}): void {
    if (!payload.rows) payload.rows = 20;
    if (!payload.page) payload.page = 1;

    this.loadingData = true;
    this.refresh = true;

    this.addfilters(payload);
    this.getLogs(payload)
      .then((logs: any) => {
        this.logs = logs.rows;
        this.pagination.total = logs.total_rows;
        this.options.page = logs.page;
      })
      .catch((error: any) => {
        console.error(error);
        if (error.message.includes("403")) this.$router.push({ name: "login" });
        this.$emit("snackbar", {
          message: "Erro ao carregar os logs!",
        });
      })
      .finally(() => {
        this.loadingTable = false;
        this.loadingData = false;
        this.refresh = false;
      });
  }

  addfilters(data: any): void {
    if (
      JSON.stringify(this.$route.query) == JSON.stringify(this.cleanObj(data))
    )
      return;
    let query: any = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value) query[key] = value;
    });

    this.setQuery(query);
  }

  setQuery(query: any = {}): void {
    let name: any = this.$route.name;
    this.$router
      .replace({
        name: name,
        query: query,
      })
      .catch(() => {
        console.log;
      });
  }

  cleanObj(obj: any): any {
    let newObj: any = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value) newObj[key] = value + "";
    });
    return newObj;
  }

  /** Retorna o id codificado para ser usado por parâmetro na URL. */
  encodeId(id: any): any {
    try {
      return btoa(id);
    } catch (error) {
      console.error("Não foi possível codificar o id", id, error);
      return "";
    }
  }
}
